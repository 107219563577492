import React from 'react'
import { Navbar, Container } from 'react-bootstrap'

export default function CapNavBar({ step, color, lang }) {

  let compMain = null;
  const myDefault = () => {
    return (
      <Navbar variant="dark" style={{backgroundColor: color}}>
        <Container>
          <Navbar.Brand href="#home">
            <img
              alt=""
              src="/logo192.png"
              width="30"
              height="30"
              className="d-inline-block align-top"
            />
          {t("Dar inicio al proceso -")}
          </Navbar.Brand>
        </Container>
      </Navbar>
    )
  }

  const stepOne = () => { return (
    <Navbar variant="dark" style={{backgroundColor: color}}>
        <Container>
          <Navbar.Brand href="#home">
            <img
              alt=""
              src="/logo192.png"
              width="30"
              height="30"
              className="d-inline-block align-top"
            />
          {t("Para tomar foto de tu cara –")}
          </Navbar.Brand>
        </Container>
      </Navbar>
  ); }
  const stepCam = () => { return (
    <Navbar variant="dark" style={{backgroundColor: color}}>
        <Container>
          <Navbar.Brand href="#home">
            <img
              alt=""
              src="/logo192.png"
              width="30"
              height="30"
              className="d-inline-block align-top"
            />
            {t("Para tomar foto de tu cara –")}
          </Navbar.Brand>
        </Container>
      </Navbar>
  ); }
  const stepThree = () => { return (
    <Navbar variant="dark" style={{backgroundColor: color}}>
        <Container>
          <Navbar.Brand href="#home">
            <img
              alt=""
              src="/logo192.png"
              width="30"
              height="30"
              className="d-inline-block align-top"
            />
            {t("Para tomar foto de tu identificación –")}
          </Navbar.Brand>
        </Container>
      </Navbar>
  ); }
  const stepCamDoc = () => { return (
    <Navbar variant="dark" style={{backgroundColor: color}}>
        <Container>
          <Navbar.Brand href="#home">
            <img
              alt=""
              src="/logo192.png"
              width="30"
              height="30"
              className="d-inline-block align-top"
            />
          {t("Para tomar foto de tu identificación –")}
          </Navbar.Brand>
        </Container>
      </Navbar>
  ); }
  const stepFive = () => { return (
    <Navbar variant="dark" style={{backgroundColor: color}}>
        <Container>
          <Navbar.Brand href="#home">
            <img
              alt=""
              src="/logo192.png"
              width="30"
              height="30"
              className="d-inline-block align-top"
            />
          {t("Para tomar foto de tu cara e identificación –")}
          </Navbar.Brand>
        </Container>
      </Navbar>
  ); }
  const stepCamDocProfile = () => { return (
    <Navbar variant="dark" style={{backgroundColor: color}}>
        <Container>
          <Navbar.Brand href="#home">
            <img
              alt=""
              src="/logo192.png"
              width="30"
              height="30"
              className="d-inline-block align-top"
            />
                    {t("Para tomar foto de tu cara e identificación –")} 
          </Navbar.Brand>
        </Container>
      </Navbar>
  ); }
  const stepSeven = () => { return (
    <Navbar variant="dark" style={{backgroundColor: color}}>
        <Container>
          <Navbar.Brand href="#home">
            <img
              alt=""
              src="/logo192.png"
              width="30"
              height="30"
              className="d-inline-block align-top"
            />
          {t("¡Todo listo!")}
          </Navbar.Brand>
        </Container>
      </Navbar>
  ); }
  function t(text) {
    var t = require('../translations/'+lang+'.json');
    return t[text];
  }
if (step === 0) {
  compMain = myDefault();
}
if (step === 1) {
  compMain = stepOne();
}
if (step === 2) {
  compMain = stepCam();
}
if (step === 3) {
  compMain = stepThree();
}
if (step === 4) {
  compMain = stepCamDoc();
}
if (step === 5) {
  compMain = stepFive();
}
if (step === 6) {
  compMain = stepCamDocProfile();    
}
if (step === 7) {
  compMain = stepSeven();    
}

return compMain;
}
