import React from 'react';
import {
  BrowserRouter,
  Routes,
  Route,
} from "react-router-dom";
import Home from './home';
import Hello from './hello';
const Webpages = () => {
    return(
        <BrowserRouter>
          <Routes>
            <Route path="/" element={<Hello />} />
            <Route exact path="/:email/:octavio/:moodle/:instancia/:firstname/:lastname/:lang" element={<Home />} />
          </Routes>
        </BrowserRouter>
    );
};
export default Webpages;