const main = {
  'primary': '#212529',
  'secondary': '#0B5ED7'
}

const barca = {
  'primary': '#004999',
  'secondary': '#A30344'
}

export const MyTheme = (ID) => {
  switch (ID) {
    case '108':
      return barca;    
  
    default:
      return main;
  }
}