import React from 'react'
import { Container, Row, Col} from 'react-bootstrap';

export default function hello() {
  return (
    <Container>
      <Row>
        <Col>
          <h1 className='text-center p-5'>Hello Capabilia Rekognition</h1>
        </Col>
      </Row>
    </Container>
  )
}
