import React from 'react'
import { Row, Col, Button } from 'react-bootstrap';

export default function BarButton(props) {

  const { isLoadingButton, next, back, step, takePhoto, uploadFile, storeUserImage, count, theme, lang } = props;

  const myDefault = () => {
    return (
      <div class="d-grid gap-2">
        <Button disabled={isLoadingButton} onClick={next} variant="primary" style={{backgroundColor: theme.primary}}>
          {isLoadingButton ? t('Cargando…') : t('Continuar')}
        </Button>
      </div>
    );
  }
  
  const stepButton = () => {
    return (
      <Row>
        <Col>
          <div class="d-grid gap-2">
            <Button disabled={isLoadingButton} onClick={back} variant="secondary" style={{backgroundColor: theme.secondary}}>
            {t("Atrás")}
            </Button>
          </div>
        </Col>
        <Col>
          <div class="d-grid gap-2">
            <Button disabled={isLoadingButton} onClick={next} variant="primary" style={{backgroundColor: theme.primary}}>
              {t("Siguiente")}
            </Button>
          </div>
        </Col>
      </Row>
    );
  }

  const stepTakePhoto = () => {
    return (
      <Row>
        <Col>
          <div class="d-grid gap-2">
            <Button disabled={isLoadingButton} onClick={back} variant="secondary" style={{backgroundColor: theme.secondary}}>
            {t("Atrás")}
            </Button>
          </div>
        </Col>
        <Col>
          <div class="d-grid gap-2">
            <Button disabled={isLoadingButton} onClick={count >= 3 ? uploadFile : takePhoto} variant="primary" style={{backgroundColor: theme.primary}}>
              { count >= 3 ? t("Sube una foto") : t("Toma una foto") }
            </Button>
          </div>
        </Col>
      </Row>
    )
  }
  
  const stepTakePhotoDoc = () => {
    return (
      <Row>
        <Col>
          <div class="d-grid gap-2">
            <Button disabled={isLoadingButton} onClick={back} variant="secondary" style={{backgroundColor: theme.secondary}}>
            {t("Atrás")}
            </Button>
          </div>
        </Col>
        <Col>
          <div class="d-grid gap-2">
            <Button disabled={isLoadingButton} onClick={count >= 3 ? uploadFile : takePhoto} variant="primary" style={{backgroundColor: theme.primary}}>
              { count >= 3 ? t("Sube una foto") : t("Toma una foto") }
            </Button>
          </div>
        </Col>
      </Row>
    )
  }

  const stepTakePhotoDocProfile = () => {
    return (
      <Row>
        <Col>
          <div class="d-grid gap-2">
            <Button disabled={isLoadingButton} onClick={back} variant="secondary" style={{backgroundColor: theme.secondary}}>
            {t("Atrás")}
            </Button>
          </div>
        </Col>
        <Col>
          <div class="d-grid gap-2">
            <Button disabled={isLoadingButton} onClick={count >= 3 ? uploadFile : takePhoto} variant="primary" style={{backgroundColor: theme.primary}}>
              { count >= 3 ? t("Sube una foto") : t("Toma una foto") }
            </Button>
          </div>
        </Col>
      </Row>
    )
  }

  const stepEnd = () => {
    return (
      <Row>
        <Col>
          <div class="d-grid gap-2">
            <Button disabled={isLoadingButton} onClick={back} variant="secondary" style={{backgroundColor: theme.secondary}}>
            {t("Atrás")}
            </Button>
          </div>
        </Col>
        <Col>
          <div class="d-grid gap-2">
            <Button disabled={isLoadingButton} onClick={storeUserImage} variant="primary" style={{backgroundColor: theme.primary}}>
            {t("Siguiente")}
            </Button>
          </div>
        </Col>
      </Row>
    )
  }
  function t(text) {
    var t = require('../translations/'+lang+'.json');
    return t[text];
  }
  let compMain = null;

  if (step === 0) {
    compMain = myDefault();
  }

  if (step === 1) {
    compMain = stepButton();
  }
  
  if (step === 2) {
    compMain = stepTakePhoto();
  }

  if (step === 3) {
    compMain = stepButton();
  }
  
  if (step === 4) {
    compMain = stepTakePhotoDoc();    
  }
  
  if (step === 5) {
    compMain = stepButton();
  }

  if (step === 6) {
    compMain = stepTakePhotoDocProfile();
  }
  
  if (step === 7) {
    compMain = stepEnd();
  }

  return compMain; 
}
