export const imageProfileLabel = (data) => {
 
  let countLabelSuccess = 0;
  const labels = [
    {
      Name: 'Person',
      Confidence: 80
    },
  ];

  labels.forEach(label => {
    data.forEach(labelIn => {
      if (labelIn.Name === label.Name && labelIn.Confidence >= label.Confidence) {
        countLabelSuccess++;
      }
    })
  });

  return countLabelSuccess === labels.length ? true : false;
}

export const imageDocLabel = (data) => {
 
  let countLabelSuccess = 0;
  const labels = [
    {
      Name: 'Person',
      Confidence: 50
    },
    {
      Name: 'Document',
      Confidence: 50
    },
    {
      Name: 'Id Cards',
      Confidence: 50
    },
    {
      Name: 'Text',
      Confidence: 50
    },
  ];

  labels.forEach(label => {
    data.forEach(labelIn => {
      if (labelIn.Name === label.Name && labelIn.Confidence >= label.Confidence) {
        countLabelSuccess++;
      }
    })
  });

  return countLabelSuccess === labels.length ? true : false;
}


export const imageProfileDocLabel = (data) => {
 
  let countLabelSuccess = 0;
  const labels = [
    {
      Name: 'Person',
      Confidence: 80,
    },
    {
      Name: 'Document',
      Confidence: 50,
    },
    {
      Name: 'Id Cards',
      Confidence: 50,
    },
  ];

  labels.forEach(label => {
    data.forEach(labelIn => {
      if (labelIn.Name === label.Name && labelIn.Confidence >= label.Confidence) {
        countLabelSuccess++;
      }
    })
  });

  return countLabelSuccess === labels.length ? true : false;
}
