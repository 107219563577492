import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import Webpages from './webpages';

function App() {
  return (
    <div>
      <Webpages />
    </div>
  );
}

export default App;
