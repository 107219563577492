import React, {useState, useRef } from 'react';
import { Container, Row, Col, Card} from 'react-bootstrap';
import { useParams } from "react-router-dom";
// *** Componenets ***
import CapNavBar from '../components/NavBar';
import ViewInfo from '../components/ViewInfo';
import BarButton from '../components/BarButton';
import Titulo from '../components/TitlesCards';
// *** Library 
import RingLoader from "react-spinners/RingLoader";
import { css } from "@emotion/react";
import Swal from 'sweetalert2'
import axios from 'axios';
// *** Rules
import { imageProfileLabel, imageDocLabel, imageProfileDocLabel } from '../rules'
// *** Utils
import { base64ToBlob } from '../utils';
// *** Theme
import { MyTheme } from '../theme';
// *** CSS
import '../css/App.css';

const override = css`
  display: block;
  margin: 0 auto;
  border-color: red;
  padding: 5px,
`;

const Home = () => {

  // *** Use
  const [sourceImage, setSourceImage] = useState('');
  const [sourceImageDoc, setSourceImageDoc] = useState('');
  const [sourceImageDocProfile, setSourceImageDocProfile] = useState('');
  const [isLoadingButton, setIsLoadingButton] = useState(false);
  const {firstname, lastname, email, moodle, instancia, octavio, lang} = useParams();
  const webcamRef = React.useRef(null);
  const [step, setStep] = useState(0);
  const [count, setCount] = useState(0);
  const [selectedFile, setSelectedFile] = useState();
	const [isFilePicked, setIsFilePicked] = useState(false);
  const refInputFile = useRef();
  
  // *** Const
  const base_url = 'https://api-rekognition-dev-dot-infra-capabilia-v2.rj.r.appspot.com/api/';
  const theme = MyTheme(instancia.substring(100));

  const validar_image_profile = (imgBase64, image) => {
    console.log('*** Validar Image Profile ***')
    let blob = null;
    if (imgBase64 !== null) {
      let base64ImageContent = imgBase64.replace(/^data:image\/(png|jpg|jpeg);base64,/, "");
      blob = base64ToBlob(base64ImageContent, 'image/png')  
    } 
    if (image !== null) {
      blob = image
    }
    let formData = new FormData(); 
    formData.append('picture', blob)
    formData.append('instancia', instancia.substring(100))
    formData.append('moodle', moodle.substring(100))
    formData.append('octavio_id', octavio)
    formData.append('email', email)
    formData.append('firstname', firstname)
    formData.append('lastname', lastname)
    setIsLoadingButton(true);
    axios({
      method: 'post',
      url: `${base_url}rekognition-service-label-detection`,
      data: formData,
      headers: { "Content-Type": "multipart/form-data" },
    }).then( (response) => {
      console.log(response.data);
      setIsLoadingButton(false)
      const {sourceImage: mySourceImage, data} = response.data; 
      let isHuman = false;
      
      // *** === *** STEP 2 Start *** ===
      if (step === 2) {
        console.log('=== *** STEP 2 *** ===')
        isHuman = imageProfileLabel(data);
        if (isHuman) {
          setSourceImage(mySourceImage);
          setStep(step + 1);
        }
        // *** When isHuman === False
        if (!isHuman) {
          Swal.fire({
            allowOutsideClick: false,
            title: t('¡Validación de Identidad!'),
            html: `
            <p>${t("La Foto del Usuario no coincide con una Persona")}</p>  
            <img src="${mySourceImage}" style="width: 50%; height: auto;" />
              `,
            icon: 'warning',
            confirmButtonColor: theme.secondary
          })

          setCount(count +1);
        }
      }
      // *** === *** STEP 2 End *** ===

      // *** === *** STEP 4 Start *** ===
      if (step === 4) {
        console.log('=== *** STEP 4 *** ===')
        if (imageDocLabel(data)) {
          setSourceImageDoc(mySourceImage);
          compareFace(mySourceImage).then((response) => {
            if (response) {
              detectText(mySourceImage);
            }
          });
        } else {
          Swal.fire({
            allowOutsideClick: false,
            title: t('¡Validación de Identidad!'),
            html: `
            <p>${t("La Foto del Usuario no coincide con una Persona o con un Documento de Identificación")}</p>  
            <img src="${mySourceImage}" style="width: 50%; height: auto;" />
              `,
            icon: 'warning',
            confirmButtonColor: theme.secondary
          })
          setCount(count + 1)
        }
      }
      // *** === *** STEP 4 End *** ===

      // *** === *** STEP 6 Start *** ===
      if (step === 6) {
        console.log('=== *** STEP 6 *** ===')

        if (imageProfileDocLabel(data)) {
          setSourceImageDocProfile(mySourceImage);
          compareFace(mySourceImage).then((response) => {
            if (response) {
              setStep(step + 1);
            }
          });
        } else {
          Swal.fire({
            allowOutsideClick: false,
            title: t('¡Validación de Identidad!'),
            html: `
            <p>${t("La Foto del Usuario no coincide con una Persona o con un Documento de Identificación")}</p>  
            <img src="${mySourceImage}" style="width: 50%; height: auto;" />
              `,
            icon: 'warning',
            confirmButtonColor: theme.secondary
          })
          setCount(count + 1)
        }
      }
      // *** === *** STEP 6 End *** ===

    }).catch(()=> {
      setIsLoadingButton(false)
    })
  }

  const storeUserImage = () => {
    console.log('*** storeUserImage ***')
    console.log(sourceImageDocProfile)
    let formData = new FormData(); 
    formData.append('instancia', instancia.substring(100))
    formData.append('moodle', moodle.substring(100))
    formData.append('octavio_id', octavio)
    formData.append('email', email)
    formData.append('firstname', firstname)
    formData.append('lastname', lastname)
    formData.append('path', sourceImage)
    formData.append('path_doc', sourceImageDoc)
    formData.append('path_doc_profile', sourceImageDocProfile)

    axios({
      method: 'post',
      url: `${base_url}rekognition-service-store-user-image`,
      data: formData,
      headers: { "Content-Type": "multipart/form-data" },
    }).then((response) => {
      console.log(response)
      const { storeImage } = response.data;
      
      if (storeImage) {
        Swal.fire({
          allowOutsideClick: false,
          title: t('¡Validación de Identidad!'),
          text: t("Proceso Completado con Éxito!"),
          icon: 'success',
          confirmButtonColor: theme.secondary
        }).then(() => {
          window.close();
        });
      }

    })
  }

  const detectText = (mySourceImage) => {
    console.log('*** DetectText ***')
    return new Promise((resolve, reject) => {
      let formData = new FormData();
      formData.append('picture', mySourceImage)
      formData.append('instancia', instancia.substring(100))
      formData.append('octavio_id', octavio)
      formData.append('moodle', moodle.substring(100))
      formData.append('email', email)
      formData.append('firstname', firstname)
      formData.append('lastname', lastname)
      setIsLoadingButton(true);

      axios({
        method: 'post',
        url: `${base_url}rekognition-service-detect-text`,
        data: formData,
        headers: { "Content-Type": "multipart/form-data" },
      }).then( (response) => {
        console.log(response)
        setIsLoadingButton(false);
        const { data } = response.data;
        
        const myFirstName = firstname.trim().toUpperCase();
        const myLastName = lastname.trim().toUpperCase();
        let hasFirstName = false;
        let hasLastName = false;
        
        data.forEach(element => {
          
          if (myFirstName.includes(element.DetectedText.toUpperCase())) {
            hasFirstName = true;
          }
          if (myLastName.includes(element.DetectedText.toUpperCase())) {
            hasLastName = true;
          }

        });

        if (hasFirstName && hasLastName) {
          console.log('FirstName && LastName === Success')
          setStep(step + 1); 
        } else {
          Swal.fire({
            allowOutsideClick: false,
            title: t('¡Validación de Identidad!'),
            text: t('El Documento no pertenece al Usuario.'),
            icon: 'warning',
            confirmButtonColor: theme.secondary
          })
        }

        resolve();
      });

    })
    
  }

  const compareFace = (mySourceImage) => {
    return new Promise((resolve, reject) => {
      let formData = new FormData(); 
      setIsLoadingButton(true);
      formData.append('sourceImage', sourceImage)
      formData.append('targetImage', mySourceImage)
      formData.append('instancia_id', instancia.substring(100))
      formData.append('user_id', moodle.substring(100))
      formData.append('octavio_id', octavio)
      formData.append('email', email)
      formData.append('firstname', firstname)
      formData.append('lastname', lastname)
      
      axios({
        method: 'post',
        url: `${base_url}rekognition-service`,
        data: formData,
        headers: { "Content-Type": "multipart/form-data" },
      }).then((response) => {
        const { data } = response.data;
        console.log(response)
        setIsLoadingButton(false);

        let required = false;

        if (data.length > 0) {
          console.log('Check Similarity')
          data.forEach(element => {
            if (element.Similarity > 65) {
              required = true
            }
          });
          
          if (required) {
            resolve(required);
          }

        }
        
        if (!required) {
          Swal.fire({
            allowOutsideClick: false,
            title: t('¡Validación de Identidad!'),
            text: t('La Foto no cumple con los requisitos.'),
            icon: 'warning',
            confirmButtonColor: theme.secondary
          })
        }
        
  
      })

    })
  }

  const next = () => {
    console.log('*** Next ***')
    setStep(step + 1);
  }

  const back = () => {
    console.log('*** Back ***')
    setStep(step - 1);
  }

  const takePhoto = () => {
    console.log('*** Take Photo ***')
    const imageSrc = webcamRef.current.getScreenshot();
    if (imageSrc === null) {
      Swal.fire({
        allowOutsideClick: false,
        title: t('¡Validación de Identidad!'),
        text: t('Para utilizar la cámara debes aceptar el permiso.'),
        icon: 'warning',
        confirmButtonColor: theme.secondary
      });
    } else {
      storeImage(imageSrc)
    }
  }

  const uploadFile = () => {
    console.log('*** uploadFile ***')
    if (isFilePicked) {
      console.log('*** Store Image ***')
      let formData = new FormData(); 
      formData.append('picture', selectedFile)
      setIsLoadingButton(true);
      axios({
        method: 'post',
        url: `${base_url}rekognition-service-store-image`,
        data: formData,
        headers: { "Content-Type": "multipart/form-data" },
      }).then(response => {
        console.log('*** Store Completed ***')
        console.log(response)
        setIsLoadingButton(false);
        setSelectedFile();
        setIsFilePicked(false);
        validar_image_profile(null, selectedFile);
      })
    } else {
      Swal.fire({
        allowOutsideClick: false,
        title: t('¡Validación de Identidad!'),
        text: t('The Field File is Required!'),
        icon: 'warning',
        confirmButtonColor: theme.secondary
      })
    }
  }

  const changeHandlerUploadProfile = (event) => {
    let next = true;
    let nextValidate = true;
    const allowedExtensions = /(\.png|\.jpg|\.jpeg)$/i;
    if (!allowedExtensions.exec(event.target.files[0].name) && nextValidate) {
      Swal.fire({
        allowOutsideClick: false,
        title: t('¡Validación de Identidad!'),
        text: t('El Archivo tiene que ser Formato(PNG/JPG/JPEG)'),
        icon: 'warning',
        confirmButtonColor: theme.secondary
      })
      next = false;
      nextValidate = false;
    }

    if (event.target.files[0].size >= 5000000 && nextValidate) {
      Swal.fire({
        allowOutsideClick: false,
        title: t('¡Validación de Identidad!'),
        text: t('El tamaño del Archivo no puede superar a los 5mb'),
        icon: 'warning',
        confirmButtonColor: theme.secondary
      })
      next = false;
      nextValidate = false;
    }

    if (next) {
      setSelectedFile(event.target.files[0]);
      setIsFilePicked(true); 
    } else {
      refInputFile.current.value = '';
    }

  }
  const storeImage = (myImageBase64) => {
    console.log('*** Store Image ***')
    let base64ImageContent = myImageBase64.replace(/^data:image\/(png|jpg);base64,/, "");
    let blob = base64ToBlob(base64ImageContent, 'image/png')  
    let formData = new FormData(); 
    formData.append('picture', blob)
    setIsLoadingButton(true);
    axios({
      method: 'post',
      url: `${base_url}rekognition-service-store-image`,
      data: formData,
      headers: { "Content-Type": "multipart/form-data" },
    }).then(response => {
      console.log('*** Store Completed ***')
      console.log(response)
      setIsLoadingButton(false);
      validar_image_profile(myImageBase64, null);
    })
  }
  function t(text) {
    var t = require('../translations/'+lang+'.json');
    return t[text];
  }
  return (
      <React.Fragment>
        <CapNavBar 
          color={theme.primary} 
          lang={lang}
          step={step} />
        <Container>
          <Row className='justify-content-md-center'>
            <Col md="6">
              <Card style={{marginTop: '5px'}}>
                
                <Card.Header style={{backgroundColor: theme.secondary, color: 'white'}}>
                  <Titulo 
                    step={step} 
                    lang={lang}
                  />
                </Card.Header>

                  <Card.Body>
                      <React.Fragment>
                        { 
                          isLoadingButton 
                          ? 
                          <Row>
                            <Col>
                            <RingLoader 
                              css={override} 
                              color={theme.secondary} 
                              loading={true} 
                              size={100} />
                              <br /> 
                            </Col>
                          </Row>
                          :
                          null
                        }
                        <ViewInfo 
                          step={step} 
                          webcamRef={webcamRef} 
                          refInputFile={refInputFile}
                          sourceImage={sourceImage} 
                          sourceImageDoc={sourceImageDoc} 
                          sourceImageDocProfile={sourceImageDocProfile}
                          count={count} 
                          changeHandlerUploadProfile={changeHandlerUploadProfile} 
                          lang={lang}
                        />
                        <br />
                        <BarButton 
                          isLoadingButton={isLoadingButton} 
                          next={next} 
                          back={back} 
                          step={step} 
                          takePhoto={takePhoto} 
                          uploadFile={uploadFile} 
                          storeUserImage={storeUserImage} 
                          count={count} 
                          theme={theme}
                          lang={lang}

                        />
                      </React.Fragment>
                    
                  </Card.Body>
              </Card>
            </Col>
          </Row>
        </Container>
      </React.Fragment>
  );
};
export default Home;