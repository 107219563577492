import React from 'react'
import { Card, Image, Row, Col, Form } from 'react-bootstrap';
// *** Library
import Webcam from "react-webcam";

export default function ViewInfo({ step, webcamRef, refInputFile, sourceImage, sourceImageDoc, sourceImageDocProfile, count, changeHandlerUploadProfile, lang }) {
  let compMain = null;
  const MyDefault = () => {
    return (
      <React.Fragment>
        <Card.Title>{t('Consideraciones previas')}</Card.Title>
        <Card.Text style={{textAlign: 'justify'}}>
        {t("Asegúrate de tener contigo una identificación personal válida (cédula, pasaporte o licencia de conducir en vigencia), ya que será solicitada antes de tomar el examen de certificación para verificar tu identidad. Luego, sigue las instrucciones de la página de inicio del Examen Final Integrador.")}
        </Card.Text>
      </React.Fragment>
    )
  }

  const StepOne = () => {
    return (
      <React.Fragment>
        <Card.Title>{t("Tu foto")}</Card.Title>
        <Card.Text>
        {t("El próximo paso solicitará que tomes una fotografía clara y nítida de tu rostro.")} 
        </Card.Text>
        <Card.Text>
       {t("Antes de avanzar, asegúrate:")}
        <ul>
          <li>{t("Que tu rostro se vea claramente")}</li>
          <li>{t("Que la habitación en la que te encuentras esté lo suficientemente iluminada para que tu rostro pueda verse de manera nítida durante todo el examen")}</li>
          <li>{t("Que tu fuente de luz no se encuentre detrás de ti")}</li>
        </ul>
        </Card.Text>
        <br />
        <Row>
          <Col className='mx-auto text-center'>
            <Image width={'30%'} height={'auto'} src="/img/camera.png" rounded />
          </Col>
        </Row>
        <br />
      </React.Fragment>
    )
  }

  const StepCam = () => {
    return (
      <Row justify-content-md-center>
        { 
          count >= 3 ?
          <Col>
          <Form.Group controlId="formFile" className="mb-3">
            <Form.Label>{t("StepCam")}</Form.Label>
            <Form.Control ref={refInputFile} onChange={changeHandlerUploadProfile} type="file" />
          </Form.Group>
          </Col>
          :
          <Col>
            <Webcam
              ref={webcamRef} 
              width={'100%'}
              height={240}
              screenshotFormat="image/png"
            />
        </Col>
        }
      </Row>
    )
  }
  
  const StepCamDoc = () => {
    return (
      <Row justify-content-md-center>
        { 
          count >= 3 ?
          <Col>
          <Form.Group controlId="formFile" className="mb-3">
            <Form.Label>{t("StepCamDoc")}</Form.Label>
            <Form.Control onChange={changeHandlerUploadProfile} type="file" />
          </Form.Group>
          </Col>
          :
          <Col>
            <Webcam
              ref={webcamRef} 
              width={'100%'}
              height={240}
              screenshotFormat="image/png"
            />
        </Col>
        }
      </Row>
    )
  }

  const StepCamDocProfile = () => {
    return (
      <Row justify-content-md-center>
        { 
          count >= 3 ?
          <Col>
          <Form.Group controlId="formFile" className="mb-3">
            <Form.Label>{t('StepCamDocProfile')}</Form.Label>
            <Form.Control onChange={changeHandlerUploadProfile} type="file" />
          </Form.Group>
          </Col>
          :
          <Col>
            <Webcam
              ref={webcamRef} 
              width={'100%'}
              height={240}
              screenshotFormat="image/png"
            />
        </Col>
        }
      </Row>
    )
  }

  const StepThree = () => {
    return (
      <React.Fragment>
        <Card.Title>{t("Tu foto")}</Card.Title>
        <Row>
          <Col className='mx-auto text-center'>
            <Image width={'50%'} height={'auto'} src={sourceImage} rounded />
          </Col>
      </Row>
      <br />
      <Card.Title>{t("Tu identificación")}</Card.Title>
      <Card.Text>
      {t("Toma una fotografía nítida de tu identificación (licencia, cédula o pasaporte vigente). El sistema necesita verificar tu nombre completo y tu foto de forma clara en tu identificación.")}
      </Card.Text>
      <Card.Text>
      {t("Algunas consideraciones:")}
      <ul>
        <li>{t("Procura que la identificación que utilices no esté dañada o ilegible.")}</li>
        <li>{t("Asegúrate que tu fuente de luz no se encuentre detrás de ti al momento de tomar la fotografía de tu identificación.")}</li>
        <li>{t("En caso de que el sistema no reconozca como válida la fotografía, tendrás la posibilidad de adjuntar tu identificación.")}</li>
      </ul>
      </Card.Text>
      <br />
      <Row>
        <Col className='mx-auto text-center'>
          <Image width={'30%'} height={'auto'} src="/img/camera.png" rounded />
        </Col>
      </Row>
      <br />
      </React.Fragment>
    );
  }

  const StepFive = () => {
    return (
      <React.Fragment>
      <Card.Title>{t("Tu foto")}</Card.Title>
      <Row>
        <Col className='mx-auto text-center'>
          <Image width={'50%'} height={'auto'} src={sourceImage} rounded />
        </Col>
      </Row>
      <br />
      <Card.Title>{t("Tu identificación")}</Card.Title>
      <Row>
        <Col className='mx-auto text-center'>
          <Image width={'50%'} height={'auto'} src={sourceImageDoc} rounded />
        </Col>
      </Row>
      <br />
      <Card.Title>{t("Tu foto + tu identificación")}</Card.Title>
      <Card.Text>
      {t("Toma una fotografía de tu rostro junto a tu identificación. No es necesario que te acerques demasiado a la cámara, solo asegúrate de que la fotografía sea clara y nítida.")}
      </Card.Text>
      <br />
      <Row>
        <Col className='mx-auto text-center'>
          <Image width={'30%'} height={'auto'} src="/img/camera.png" rounded />
        </Col>
      </Row>
      <br />
      </React.Fragment>
    )
  }
  
  const StepSeven = () => {
    return (
      <React.Fragment>
      <Card.Title>{t('Tu foto')}</Card.Title>
      <Row>
        <Col>
          <Image width={'100%'} height={'auto'} src={sourceImage} rounded />
        </Col>
      </Row>
      <br />
      <Card.Title>{t("Tu identificación")}</Card.Title>
      <Row>
        <Col>
          <Image width={'100%'} height={'auto'} src={sourceImageDoc} rounded />
        </Col>
      </Row>
      <br />
      <Card.Title>{t("Tu Foto + tu identimación")}</Card.Title>
      <Row>
        <Col>
          <Image width={'100%'} height={'auto'} src={sourceImageDocProfile} rounded />
        </Col>
      </Row>
      </React.Fragment>
    )
  }
  function t(text) {
    var t = require('../translations/'+lang+'.json');
    return t[text];
  }
  if (step === 0) {
    compMain = MyDefault();
  }
  if (step === 1) {
    compMain = StepOne();
  }
  if (step === 2) {
    compMain = StepCam();
  }
  if (step === 3) {
    compMain = StepThree();
  }
  if (step === 4) {
    compMain = StepCamDoc();
  }
  if (step === 5) {
    compMain = StepFive();
  }
  if (step === 6) {
    compMain = StepCamDocProfile();    
  }
  if (step === 7) {
    compMain = StepSeven();    
  }

  return compMain;
}
