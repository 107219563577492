export default function TitlesCards({ step, lang }) {

  let compMain = null;
  const myDefault = () => {
    return t("Validación de identidad");
  }

  const stepOne = () => { return t("Validación de identidad"); }
  const stepCam = () => { return t("Validación de identidad"); }
  const stepThree = () => { return t("Validación de identidad"); }
  const stepCamDoc = () => { return t("Validación de identidad"); }
  const stepFive = () => { return t("Validación de identidad"); }
  const stepCamDocProfile = () => { return t("Validación de identidad"); }
  const stepSeven = () => { return t("Validación de identidad"); }
  function t(text) {
    var t = require('../translations/'+lang+'.json');
    return t[text];
  }
if (step === 0) {
  compMain = myDefault();
}
if (step === 1) {
  compMain = stepOne();
}
if (step === 2) {
  compMain = stepCam();
}
if (step === 3) {
  compMain = stepThree();
}
if (step === 4) {
  compMain = stepCamDoc();
}
if (step === 5) {
  compMain = stepFive();
}
if (step === 6) {
  compMain = stepCamDocProfile();    
}
if (step === 7) {
  compMain = stepSeven();    
}

return compMain;
}
